define('directory/components/search-box', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    searchTerms: Ember['default'].inject.service(),

    actions: {
      search: function search() {
        this.sendAction('search');
      }
    }
  });

});