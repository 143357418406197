define('directory/services/search-terms', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend({
    content: '',

    empty: function empty() {
      this.set('content', '');
    }
  });

});