define('directory/routes/churches', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.peekRecord('church', params.church_id);
    },

    actions: {
      goBack: function goBack() {
        window.history.back();
      }
    }
  });

});