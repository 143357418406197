define('directory/routes/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return this.store.findAll('region');
    },

    searchTerms: Ember['default'].inject.service(),

    actions: {
      setState: function setState(state) {
        this.get('searchTerms').empty();
        this.transitionTo('/states/' + state);
      },

      setRegion: function setRegion(region) {
        this.get('searchTerms').empty();
        this.transitionTo('/regions/' + region);
      },

      setDistrict: function setDistrict(district) {
        this.get('searchTerms').empty();
        this.transitionTo('/districts/' + district);
      }
    }
  });

});