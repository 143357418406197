define('directory/components/jquery-ready', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      Ember['default'].$('.loader').remove();
      Ember['default'].$('.accordion-tabs-minimal').each(function (index) {
        Ember['default'].$(this).children('li').first().children('a').addClass('is-active').next().addClass('is-open').show();
      });
      Ember['default'].$('.accordion-tabs-minimal').on('click', 'li > a.tab-link', function (event) {
        if (!Ember['default'].$(this).hasClass('is-active')) {
          event.preventDefault();
          var accordionTabs = Ember['default'].$(this).closest('.accordion-tabs-minimal');
          accordionTabs.find('.is-open').removeClass('is-open').hide();

          Ember['default'].$(this).next().toggleClass('is-open').toggle();
          accordionTabs.find('.is-active').removeClass('is-active');
          Ember['default'].$(this).addClass('is-active');
        } else {
          event.preventDefault();
        }
      });
    }
  });

});