define('directory/models/minister', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    lastName: DS['default'].attr(),
    firstName: DS['default'].attr(),
    email: DS['default'].attr(),
    city: DS['default'].attr(),
    state: DS['default'].belongsTo('state', { async: true }),
    phone: DS['default'].attr(),
    profile: DS['default'].attr(),
    church: DS['default'].belongsTo('church', { async: true }),
    district: DS['default'].belongsTo('district', { async: true }),
    region: DS['default'].belongsTo('region', { async: true }),

    name: Ember['default'].computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),

    cityState: Ember['default'].computed('city', 'state', function () {
      if (this.get('city') && this.get('state.id')) {
        return this.get('city') + ', ' + this.get('state.id');
      }
    })
  });

});