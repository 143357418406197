define('directory/routes/results', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return { churches: this.store.peekAll('church'), ministers: this.store.peekAll('minister') };
    }
  });

});