define('directory/models/state', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr(),
    churches: DS['default'].hasMany('church', { async: true }),
    ministers: DS['default'].hasMany('minister', { async: true })
  });

});