define('directory/router', ['exports', 'ember', 'directory/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.map(function () {
    this.route('regions', { path: '/regions/:region_id' });
    this.route('states', { path: '/states/:state_id' });
    this.route('districts', { path: '/districts/:district_id' });
    this.route('churches', { path: '/churches/:church_id' });
    this.route('ministers', { path: '/ministers/:minister_id' });
    this.route('results');
  });

  exports['default'] = Router;

});