define('directory/components/directory-results', ['exports', 'ember', 'ember-cli-filter-by-query', 'ember-group-by'], function (exports, Ember, computedFilterByQuery, groupBy) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    // sorting: ['state', 'city', 'name'],
    // sortedChurches: Ember.computed.sort('model.churches', 'sorting'),
    // sortedMinisters: Ember.computed.sort('model.ministers', 'sorting'),
    searchTerms: Ember['default'].inject.service(),

    searchedChurches: computedFilterByQuery['default']('model.churches', ['name', 'city'], 'searchTerms.content', { conjunction: 'and', sort: false }).readOnly(),
    searchedMinisters: computedFilterByQuery['default']('model.ministers', ['lastName', 'firstName'], 'searchTerms.content', { conjunction: 'and', sort: false }).readOnly(),

    searchedChurchesByCity: groupBy['default']('searchedChurches', 'cityState'),
    searchedMinistersByCity: groupBy['default']('searchedMinisters', 'cityState')
  });

});