define('directory/routes/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('application');
    },

    searchTerms: Ember['default'].inject.service(),

    setupController: function setupController(controller, model) {
      controller.set('regions', this.store.peekAll('region'));
      controller.set('districts', this.store.peekAll('district'));
    },
    actions: {
      search: function search() {
        this.transitionTo('results');
      }

    }
  });
  // didTransition: function() {
  //   this.get('searchTerms').empty();
  // },

});