define('directory/tests/unit/helpers/state-code-to-state-test', ['directory/helpers/state-code-to-state', 'qunit'], function (state_code_to_state, qunit) {

  'use strict';

  qunit.module('Unit | Helper | state code to state');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = state_code_to_state.stateCodeToState(42);
    assert.ok(result);
  });

});