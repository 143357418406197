define('directory/routes/ministers', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    actions: {
      goBack: function goBack() {
        window.history.back();
      }
    }
  });

});