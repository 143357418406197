define('directory/components/directory-map', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: 'map-container',

    didInsertElement: function didInsertElement() {
      Ember['default'].run.scheduleOnce('afterRender', this, function () {
        var self = this;
        Ember['default'].$('#map').usmap({
          showLabels: true,
          stateStyles: {
            fill: '#cccccc',
            stroke: '#e1e1e1'
          },
          stateHoverStyles: {
            fill: '#b4111a'
          },
          labelBackingStyles: {
            fill: '#cccccc',
            stroke: '#e1e1e1'
          },
          labelBackingHoverStyles: {
            fill: '#b4111a'
          },
          click: function click(event, data) {
            // console.log(data.name);
            self.sendAction('setState', data.name);
          }
        });
      });
    }
  });

});