define('directory/routes/states', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.peekRecord('state', params.state_id);
    }
  });

});