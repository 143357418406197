define('directory/models/church', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

    'use strict';

    exports['default'] = DS['default'].Model.extend({
        name: DS['default'].attr(),
        email: DS['default'].attr(),
        website: DS['default'].attr(),
        district: DS['default'].belongsTo('district', { async: true }),
        region: DS['default'].belongsTo('region', { async: true }),
        phone: DS['default'].attr(),
        fax: DS['default'].attr(),
        address1: DS['default'].attr(),
        address2: DS['default'].attr(),
        city: DS['default'].attr(),
        state: DS['default'].belongsTo('state', { async: true }),
        postalCode: DS['default'].attr(),
        ministers: DS['default'].hasMany('minister', { async: true }),

        cityState: Ember['default'].computed('city', 'state', function () {
            if (this.get('city') && this.get('state.id')) {
                return this.get('city') + ', ' + this.get('state.id');
            }
        }),

        pastor: Ember['default'].computed('ministers', function () {
            if (this.get('ministers.length') > 0) {
                var p = this.get('ministers').findBy('profile', 'PSTR-S');
                if (p) {
                    return p;
                }
            }
        }),

        staff: Ember['default'].computed('ministers', function () {
            if (this.get('ministers.length') > 0) {
                var s = this.get('ministers').rejectBy('profile', 'PSTR-S');
                if (s) {
                    return s;
                }
            }
        })
    });

});