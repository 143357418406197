define('directory/models/region', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr(),
    address1: DS['default'].attr(),
    address2: DS['default'].attr(),
    city: DS['default'].attr(),
    state: DS['default'].belongsTo('state', { async: true }),
    postalCode: DS['default'].attr(),
    phone: DS['default'].attr(),
    fax: DS['default'].attr(),
    email: DS['default'].attr(),
    website: DS['default'].attr(),
    churches: DS['default'].hasMany('church', { async: true }),
    ministers: DS['default'].hasMany('minister', { async: true }),
    districts: DS['default'].hasMany('district', { async: true })
  });

});