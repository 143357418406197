define('directory/routes/regions', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.peekRecord('region', params.region_id);
    }
  });

});