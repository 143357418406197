define('directory/routes/districts', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.peekRecord('district', params.district_id);
    }
  });

});