define('directory/transitions', ['exports'], function (exports) {

  'use strict';

  exports['default'] = function () {
    this.transition(this.fromRoute('index'), this.toRoute('states'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('index'), this.toRoute('regions'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('index'), this.toRoute('districts'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('index'), this.toRoute('results'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('regions'), this.toRoute('churches'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('regions'), this.toRoute('ministers'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('states'), this.toRoute('churches'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('states'), this.toRoute('ministers'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('results'), this.toRoute('churches'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('results'), this.toRoute('ministers'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('districts'), this.toRoute('churches'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('districts'), this.toRoute('ministers'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('churches'), this.toRoute('ministers'), this.use('toLeft'), this.reverse('toRight'));
  }

});